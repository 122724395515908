import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  ACCOUNTS,
  BULK_POST_PLACEMENT,
  BUSINESS_SETTINGS,
  COMPLAINT,
  PARAMETER_MANAGER,
  POST_PLACEMENT_MANAGER,
  RESTING_AREA,
  STRATEGIES,
  STRATEGY,
} from 'src/presentation/routes'
import { AuthContext } from 'src/context/AuthenticationContext'

import {
  Icon,
  SideMenu,
  SideMenuItem,
  SideMenuSubItem,
} from 'everchain-uilibrary'
import {
  AppsPermissionCodeAccess,
  PermissionCodeAccess,
} from 'src/utils/constants'
import { AbilityContext, Can } from 'src/context/Can'
import { getStandardUri, permissionReduce } from 'src/utils/common'

const Menus = () => {
  const history = useHistory()
  const ability = useContext(AbilityContext)
  const canCreateStrategy = ability.can(
    PermissionCodeAccess.Recover_CreateStrategies,
    'any'
  )

  const [pageUrl, setPageUrl] = useState(history.location.pathname)
  const { managePermissions, userPermissions, isVendor } =
    useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const handleInternalRedirect = (uri: string) => {
    window.localStorage.removeItem('strategiesLoaded')
    window.localStorage.removeItem('filesFilterStorage')
    window.localStorage.removeItem('accountsFilterStorage')
    window.localStorage.removeItem('accountsFiltered')
    history.push(getStandardUri(uri))
  }

  const managePermissionReduce = permissionReduce(managePermissions)

  useEffect(() => {
    setPageUrl(history.location.pathname)
  }, [history.location.pathname])

  return (
    <SideMenu>
      <SideMenuItem
        id="menu-accounts"
        title="Accounts"
        onClick={() => handleInternalRedirect(ACCOUNTS)}
        icon={<Icon name="Folder" fontSize="small" />}
        defaultOpen={pageUrl.indexOf(ACCOUNTS) !== -1}
      >
        <SideMenuSubItem
          title="Accounts In Strategy"
          id="recover-item-accounts-strategy"
          onClick={() => handleInternalRedirect(ACCOUNTS)}
          defaultOpen={pageUrl.indexOf(ACCOUNTS) !== -1}
          icon={<Icon name="FolderOpen" fontSize="small" />}
        />
        <SideMenuSubItem
          title="Post-Placement Manager"
          id="recover-post-placement-manager"
          onClick={() => handleInternalRedirect(POST_PLACEMENT_MANAGER)}
          defaultOpen={pageUrl.indexOf(POST_PLACEMENT_MANAGER) !== -1}
          icon={<Icon name="SyncAlt" fontSize="small" />}
        />
        <SideMenuSubItem
          title="Create Bulk"
          id="recover-create-bulk-post-placement"
          onClick={() => handleInternalRedirect(BULK_POST_PLACEMENT)}
          defaultOpen={pageUrl.indexOf(BULK_POST_PLACEMENT) !== -1}
          icon={<Icon name="Add" fontSize="small" />}
        />
      </SideMenuItem>
      {(isInternal || !isVendor) && (
        <SideMenuItem
          id="recover-item-resting-area"
          title="Resting Area"
          onClick={() => handleInternalRedirect(RESTING_AREA)}
          icon={<Icon name="Pending" fontSize="small" />}
          defaultOpen={pageUrl.indexOf(RESTING_AREA) !== -1}
        />
      )}
      {canCreateStrategy && !isVendor && (
        <SideMenuItem
          id="menu-strategies"
          title="Strategies"
          onClick={() => handleInternalRedirect(STRATEGIES)}
          icon={<Icon name="CallSplit" fontSize="small" />}
          defaultOpen={
            pageUrl.indexOf(STRATEGIES) !== -1 ||
            pageUrl.indexOf(STRATEGY) !== -1
          }
        >
          <SideMenuSubItem
            title="Strategy Manager"
            id="recover-item-strategy-manager"
            onClick={() => handleInternalRedirect(STRATEGIES)}
            defaultOpen={
              pageUrl.indexOf(STRATEGIES) !== -1 ||
              pageUrl.indexOf(STRATEGY) !== -1
            }
            icon={<Icon name="FolderOpen" fontSize="small" />}
          />
          <SideMenuSubItem
            title="Parameters Manager"
            id="recover-item-strategy-parameters-manager"
            onClick={() => handleInternalRedirect(PARAMETER_MANAGER)}
            defaultOpen={pageUrl.indexOf(PARAMETER_MANAGER) !== -1}
            icon={<Icon name="Add" fontSize="small" />}
          />
        </SideMenuItem>
      )}
      {isInternal && (
        <SideMenuItem
          id="recover-item-business-settings"
          title="Business Settings"
          onClick={() => handleInternalRedirect(BUSINESS_SETTINGS)}
          icon={<Icon name="SupervisorAccount" fontSize="small" />}
          defaultOpen={pageUrl.indexOf(BUSINESS_SETTINGS) !== -1}
        />
      )}
      <Can do={PermissionCodeAccess.Recover_Complaint} on="any">
        <SideMenuItem
          id="recover-item-complaint"
          title="Complaints"
          onClick={() => handleInternalRedirect(COMPLAINT)}
          icon={<Icon name="QuestionAnswer" fontSize="small" />}
          defaultOpen={pageUrl.indexOf(COMPLAINT) !== -1}
        />
      </Can>
      {managePermissionReduce[AppsPermissionCodeAccess.Marketplace] && (
        <SideMenuItem
          id="menu-marketplace"
          title="Marketplace"
          onClick={() =>
            window.open(process.env.REACT_APP_MARKETPLACE_SITE, '_self')
          }
          icon={<Icon name="AccountBalanceWallet" fontSize="small" />}
        />
      )}
      {managePermissionReduce[AppsPermissionCodeAccess.Control_Panel] && (
        <SideMenuItem
          id="menu-control-panel"
          title="Control Panel"
          onClick={() =>
            window.open(process.env.REACT_APP_CONTROLPANEL_SITE, '_self')
          }
          icon={<Icon name="SettingsApplicationsOutlined" fontSize="small" />}
        />
      )}
      {managePermissionReduce[
        AppsPermissionCodeAccess.Compliance_Management_System
      ] && (
        <SideMenuItem
          id="menu-compliance"
          title="Compliance Management"
          onClick={() => window.open(process.env.REACT_APP_CMS_SITE, '_self')}
          icon={<Icon name="PlaylistAddCheckOutlined" fontSize="small" />}
        />
      )}
      {managePermissionReduce[AppsPermissionCodeAccess.Monitor] && (
        <SideMenuItem
          id="menu-monitoring"
          title="Monitoring"
          onClick={() =>
            window.open(process.env.REACT_APP_MONITOR_SITE, '_self')
          }
          icon={<Icon name="SettingsSystemDaydreamOutlined" fontSize="small" />}
        />
      )}
    </SideMenu>
  )
}

Menus.defaultProps = {
  opendedMenu: false,
}

export default Menus
